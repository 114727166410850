
import {Vue} from 'vue-class-component';

export default class Welcome extends Vue {
  mounted(): void {
    const ratio = 0.00000001;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: ratio
    };

    const handleIntersect = function (entries: Array<IntersectionObserverEntry>, observer: IntersectionObserver) {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.intersectionRatio > ratio) {
          entry.target.classList.remove('reveal');
          observer.unobserve(entry.target);
        }
      });
    };

    document.documentElement.classList.add('reveal-loaded');
    window.addEventListener('DOMContentLoaded', function () {
      const observer = new IntersectionObserver(handleIntersect, options);
      document.querySelectorAll('.reveal').forEach(reveal => {
        observer.observe(reveal);
      });
    });
  }

  switchExpandedState(elementId: string): void {
    console.log(elementId);
    const toExpandElement = document.getElementById(elementId);

    if (toExpandElement)
      if (toExpandElement.classList.contains('isExpanded')) {
        toExpandElement.classList.remove('isExpanded');
      } else {
        toExpandElement.classList.add('isExpanded');
      }
  }

  shadow() {
    console.log('shadow');
    let htmlCollectionOfNewSection = document.getElementsByClassName('new-section');

    for (let i = 0; i < htmlCollectionOfNewSection.length; i++) {
      let element = htmlCollectionOfNewSection[i];
      element.classList.add('new');
    }
  }
}
